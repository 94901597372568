import React from "react";
import { SignIn } from "aws-amplify-react";
import config from "./aws-exports";
import { CustomSignIn } from "./Login";
import App from "./App.js";
import { Authenticator } from "aws-amplify-react/dist/Auth";
import SignOut from "aws-amplify-react/dist/Auth/SignOut";

class AppWithAuth extends React.Component {

  render() {
    return (
      <div>
        <Authenticator hide={[SignIn,SignOut]} amplifyConfig={config}>
          <CustomSignIn />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;
