import React, {Component} from 'react';
import { Divider } from 'semantic-ui-react';
import * as Constants from './Constants';
import { API } from 'aws-amplify';
import ReactTable from 'react-table';
import Moment from 'react-moment';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { RotateLoader } from 'react-spinners';
import { css } from '@emotion/core';
const CheckboxTable = checkboxHOC(ReactTable);

const override = css`
    display: block;
    margin: auto;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
`;

class Sessions extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      selection: [],
      selectAll: false,
      isLoaded: false,
    };
  }

  componentDidMount(){
    this.getSessions();
  }

  getSessions(){
    API.get(
      Constants.API_NAME,
      Constants.API_EP_SESSIONS,
      {}
    ).then(r => {
      this.setState(
        {
          data:r,
          isLoaded: true
        }
      );
    }).catch(error => {
      console.log(error)
    });
  }

  toggleSelection = (key, shift, row) => {
    key = row.request_id;
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original.request_id);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = key => {
    return this.state.selection.includes(key);
  };

  getTable(isLoaded, data, columns, checkboxProps){
    if (isLoaded){
      return (
        <CheckboxTable
          ref={r => (this.checkboxTable = r)}
          data={data}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
          {...checkboxProps}
          defaultSorted={[
            {
              id: "request_time_epoch",
              desc: true
            }
          ]}
        />
      );
    } else {
      return;
    }
  }

  render(){

    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, selectAll, isLoaded } = this.state;

    const checkboxProps = {
      keyField: "request_id",
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (s, r) => {
        if (r == null){
          return {
            style: {
            }
          };
        }
        if (isLoaded){
          const selected = this.isSelected(r.original.request_id);
          return {
            style: {
              backgroundColor: selected ? "lightgreen" : "inherit"
            }
          };
        } else {
          return {
            style: {
            }
          };
        }
      }
    };
   
    const columns = [
      {
        Header: 'Requested',
        accessor: 'request_time_epoch',
        Cell: props => <Moment unix fromNow>{props.value/1000}</Moment>,
        minWidth: 30,
      },
      {
        Header: 'Name',
        accessor: 'full_name',
        minWidth: 40,
      },
      {
        Header: 'Status',
        accessor: 'session_status',
        minWidth: 35,
      },
      {
        Header: 'Instance Id',
        accessor: 'instance_id',
        minWidth: 55,
      },
      {
        Header: 'Username',
        accessor: 'username',
        minWidth: 35,
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
    ]

    return (
      <div>
        <Divider clearing hidden/>
        <RotateLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#00aef5'}
          loading={!this.state.isLoaded}
        />
        {this.getTable(isLoaded,data,columns,checkboxProps)}
      </div>
    );
  }
}

export default Sessions;