import React from "react";
import { SignIn } from "aws-amplify-react";
import { Button, Form, Grid, Image, Segment } from 'semantic-ui-react'

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 350 }}>
      <Image src='./fletch.png' />
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              fluid
              icon='user'
              placeholder="username"
              iconPosition='left'
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              type="text"
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              type='password'
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
              placeholder="******************"
            />
            <Button
              color='color1'
              fluid size='large'
              onClick={() => super.signIn()}
            >
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
    );
  }
}
