import React, {Component} from 'react';
import aws_exports from './aws-exports';
import Amplify from 'aws-amplify';
import * as Constants from './Constants';
import Users from './Users';
import Sessions from './Sessions';
import Instances from './Instances';
import { Menu, Container, Image } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';

Amplify.configure(aws_exports);

Amplify.configure(
  {
    API: {
      endpoints: [
          {
              name: Constants.API_NAME,
              endpoint: Constants.API_URL,
              service: Constants.SERVICE,
              region: Constants.API_REGION,
              custom_header: async () => { 
                return {
                  Authorization: (
                    await Amplify.Auth.currentSession()
                  ).idToken.jwtToken 
                }
              }
          }
      ]
    }
  }
);

class App extends Component {
  state = {
    activeItem: Constants.SCREEN_NAME_SESSIONS,
    activeContent: <Sessions />
  }

  handleItemClick(e, { name }){
    this.setState({ activeItem: name });
    if (name === Constants.SCREEN_NAME_USERS) {
      this.setState({activeContent: <Users />})
    } else if (name === Constants.SCREEN_NAME_SESSIONS) {
      this.setState({activeContent: <Sessions />})
    } else if (name === Constants.SCREEN_NAME_INSTANCES) {
      this.setState({activeContent: <Instances />})
    }
  }

  signOut = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }

  render() {
    const { activeItem, activeContent } = this.state
    if (this.props.authState === "signedIn") {
      return (
        <Container>
          <Image src='./fletch-slim.png' size='small'/>
          <Menu pointing secondary>
            <Menu.Item
              name={Constants.SCREEN_NAME_SESSIONS}
              active={activeItem === Constants.SCREEN_NAME_SESSIONS}
              onClick={this.handleItemClick.bind(this)}
            />
            <Menu.Item
              name={Constants.SCREEN_NAME_INSTANCES}
              active={activeItem === Constants.SCREEN_NAME_INSTANCES}
              onClick={this.handleItemClick.bind(this)}
            />
            <Menu.Item
              name={Constants.SCREEN_NAME_USERS}
              active={activeItem === Constants.SCREEN_NAME_USERS}
              onClick={this.handleItemClick.bind(this)}
            />
            <Menu.Menu position='right'>
              <Menu.Item
                name='Sign out'
                onClick={this.signOut}
              />
            </Menu.Menu>
          </Menu>
          {activeContent}
        </Container>
      );
    } else {
      return null;
    }
  }
}
export default App;
