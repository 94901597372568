import React, {Component} from 'react';
import { Button, Modal, Form, Divider, Dropdown, Menu, Icon,TextArea,Message } from 'semantic-ui-react';
import * as Constants from './Constants';
import { API } from 'aws-amplify';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import { RotateLoader } from 'react-spinners';
import { css } from '@emotion/core';
import matchSorter from 'match-sorter';
const CheckboxTable = checkboxHOC(ReactTable);

const override = css`
    display: block;
    margin: auto;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const optionsSingle = [
  { key: 1, text: 'Connect', value: 'connect' },
]

class Instances extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      selection: [],
      selectAll: false,
      isLoaded: false,
      isLoadingCreds: false,
      sessionName: "",
      sessionDescription: "",
      connectInstanceModalOpen: false,
      connectGetDetailsModalOpen: false,
      createModalError: false,
      connectModalError: false,
      sessionUsername: "",
      sessionPassword: "",
      sessionComputer: "",
      copied: false,
      connectGetDetailsComment: ""
    };
  }

  componentDidMount(){
    this.getInstances();
  }

  getInstances(){
    API.get(
      Constants.API_NAME,
      Constants.API_EP_INSTANCES_GET,
      {}
    ).then(r => {
      this.setState(
        {
          data:r,
          isLoaded: true
        }
      );
    }).catch(error => {
      console.log(error)
    });
  }

  handleConnectGetDetailsSubmitClick(){
    const {connectGetDetailsComment,selection} = this.state;
    if (connectGetDetailsComment === ""){
      this.setState(
        {
          createModalError: true,
          createModalErrorMessage: "Please enter a comment"
        }
      );
      return;
    }
    this.setState(
      {
        isLoadingCreds: true
      }
    )
    API.post(
      Constants.API_NAME,
      Constants.API_EP_INSTANCES_CONNECT,
      {
        body: {
          instance_id: selection[0],
          comment: connectGetDetailsComment
        }
      }
    ).then(r => {
      this.setState(
        {
          createModalError: false,
          connectGetDetailsModalOpen: false,
          connectInstanceModalOpen:true,
          sessionUsername: r.username,
          sessionPassword: r.password,
          sessionComputer: r.computer,
          isLoadingCreds: false
        }
      );
      
    }).catch(error => {
      console.log(error)
      this.setState(
        {
          createModalError: true,
          createModalErrorMessage: "Unable to create local user. Check server health."
        }
      );
    });
  }

  toggleSelection = (key, shift, row) => {
    key = row.InstanceId;
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original.InstanceId);
      });
    }
    this.setState({ selectAll, selection });
  };
  isSelected = key => {
    return this.state.selection.includes(key);
  };

  onChangeAction(e,data){
    const {selection} = this.state;
    if (data.value === 'connect'){
      const count = selection.length;
      if (count === 1) {
        this.setState({connectGetDetailsModalOpen:true});
      }
    }
  }

  connectInstanceModal(){
    const {
      connectInstanceModalOpen,
      createModalError,
      sessionUsername,
      sessionPassword,
      sessionComputer
    } = this.state;
    return (
      <Modal
        open={connectInstanceModalOpen}
        onOpen={()=>{this.setState({connectInstanceModalOpen: true})}}
        onClose={()=>{this.setState({connectInstanceModalOpen: false})}}
        size='mini'>
        <Modal.Header>Connect</Modal.Header>
        <Modal.Content>
        <Form error={createModalError}>
          <Form.Field inline>
            <label>Computer</label>
            <input readOnly value={sessionComputer}/>
            <CopyToClipboard text={sessionComputer}
              onCopy={() => this.setState({copied: true})}>
              <Button icon>
                <Icon name='copy' />
              </Button>
            </CopyToClipboard>
          </Form.Field>
          <Form.Field inline>
            <label>Username</label>
            <input readOnly value={sessionUsername}/>
            <CopyToClipboard text={sessionUsername}
              onCopy={() => this.setState({copied: true})}>
              <Button icon>
                <Icon name='copy' />
              </Button>
            </CopyToClipboard>
          </Form.Field>
          <Form.Field inline>
            <label>Password</label>
            <input readOnly value={sessionPassword} />
            <CopyToClipboard text={sessionPassword}
              onCopy={() => this.setState({copied: true})}>
              <Button icon>
                <Icon name='copy' />
              </Button>
            </CopyToClipboard>
          </Form.Field>
        </Form>
        </Modal.Content>
      </Modal>
    )
  }

  connectGetDetailsModal(){
    const {connectGetDetailsModalOpen, createModalErrorMessage, createModalError} = this.state;
    return (
      <Modal
        open={connectGetDetailsModalOpen}
        onOpen={()=>{this.setState({connectGetDetailsModalOpen: true})}}
        onClose={()=>{this.setState({connectGetDetailsModalOpen: false})}}
        size='mini'>
        <Modal.Header>Comment</Modal.Header>
        <Modal.Content>
        <Form error={createModalError}>
          <Form.Field>
            <TextArea
              placeholder="Why are you doing this? &#10; Your comments will be logged and audited by Telstra ISO"
              onChange={(e, { value }) => this.setState({connectGetDetailsComment:value})}
            />
            <Message
              error
              content={createModalErrorMessage}
            />
          </Form.Field>
          <Button
            type='submit'
            onClick={this.handleConnectGetDetailsSubmitClick.bind(this)}
            loading={this.state.isLoadingCreds}
            disabled={this.state.isLoadingCreds}
          >
            Connect
          </Button>
        </Form>
        </Modal.Content>
      </Modal>
    )
  }


  getActionsDropdownMenu(){
    const { selection } = this.state;
    const count = selection.length;
    let actions;
    if (count === 1){
      actions = (
        <Menu compact floated='right'>
          <Dropdown
            text='Actions'
            options={optionsSingle}
            simple
            value={null}
            item
            onChange={this.onChangeAction.bind(this)}
          />
        </Menu>
      )
    } else if (count >= 1){
      actions = (
        <Menu compact floated='right'>
          <Dropdown
            text='Actions'
            simple
            value={null}
            item
            onChange={this.onChangeAction.bind(this)}
          />
        </Menu>
      )
    } else if (count === 0){
      actions = (
        <Menu compact floated='right'>
          <Dropdown
            text='Actions'
            disabled
            simple
            value={null}
            item
            onChange={this.onChangeAction.bind(this)}
          />
        </Menu>
      )
    }
    return actions
  }

  getTable(isLoaded, data, columns, checkboxProps){
    if (isLoaded){
      return (
        <CheckboxTable
          filterable
          ref={r => (this.checkboxTable = r)}
          data={data}
          columns={columns}
          defaultPageSize={50}
          className="-striped -highlight"
          {...checkboxProps}
          defaultSorted={[
            {
              id: "StackName",
              desc: true
            }
          ]}
        />
      );
    } else {
      return;
    }
  }

  render(){

    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, selectAll, isLoaded } = this.state;

    const checkboxProps = {
      keyField: "InstanceId",
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (s, r) => {
        if (r == null){
          return {
            style: {
            }
          };
        }
        if (isLoaded){
          const selected = this.isSelected(r.original.InstanceId);
          return {
            style: {
              backgroundColor: selected ? "lightgreen" : "inherit"
            }
          };
        } else {
          return {
            style: {
            }
          };
        }
      }
    };
   
    const columns = [
      {
        Header: 'Name',
        accessor: 'InstanceName',
        minWidth: 70,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["InstanceName"] }),
        filterAll: true
      },
      {
        Header: 'Stack',
        accessor: 'StackName',
        minWidth: 90,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["StackName"] }),
        filterAll: true
      },
      {
        Header: 'Host Name',
        accessor: 'ComputerName',
        minWidth: 50,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["ComputerName"] }),
        filterAll: true
      },
      {
        Header: 'Ping Status',
        accessor: 'PingStatus',
        minWidth: 30,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["PingStatus"] }),
        filterAll: true,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === 'ConnectionLost' ? '#ff2e00'
                : row.value === 'Inactive' ? '#000000'
                : '#57d500',
              transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span>
            {row.value}
          </span>
        )
      },
      {
        Header: 'Last Ping',
        accessor: 'LastPingDateTime',
        minWidth: 40,
        Cell: props => <Moment fromNow>{props.value}</Moment>,
      },
      {
        Header: 'Instance ID',
        accessor: 'InstanceId',
        minWidth: 55,
      },
      {
        Header: 'Platform Name',
        accessor: 'PlatformName',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["PlatformName"] }),
        filterAll: true,
      },
      {
        Header: 'Agent Version',
        accessor: 'AgentVersion',
        minWidth: 40,
      }
    ]

    return (
      <div>
        {this.connectInstanceModal()}
        {this.connectGetDetailsModal()}
        {this.getActionsDropdownMenu()}
        <Divider clearing hidden/>
        <RotateLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#00aef5'}
          loading={!this.state.isLoaded}
        />
        {this.getTable(isLoaded,data,columns,checkboxProps)}
      </div>
    );
  }

}

export default Instances;