export const MENU_TITLE_AUDIT = "Audit";
export const MENU_TITLE_SESSIONS = "Sessions";
export const MENU_TITLE_INSTANCES = "Instances";
export const MENU_TITLE_USERS = "Users";
export const HEADER_LABEL = "Fletch";
export const API_NAME = 'fletch';
export const API_REGION = 'ap-southeast-2';
export const SERVICE = 'lambda';
export const API_URL = `${process.env.REACT_APP_API_URL}` || "https://fletch-api.mtdata.com.au/"
export const API_EP_USERS = "users";
export const API_EP_SESSIONS = "sessions";
export const API_EP_INSTANCES_GET = "instances";
export const API_EP_INSTANCES_CONNECT = "instances/connect";
export const API_EP_SESSIONS_CREATE = "sessions";
export const API_EP_SESSIONS_DELETE = "sessions/delete";
export const SCREEN_NAME_SESSIONS = 'sessions';
export const SCREEN_NAME_USERS = 'users';
export const SCREEN_NAME_INSTANCES = 'instances';
